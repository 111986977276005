<template>
  <div>

    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Показывать по</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>записей</label>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Поиск..."
            />
          </div>
        </b-col>
      </b-row>
    </b-card>

    <section id="knowledge-base-content">
      <b-row class="kb-search-content-info match-height">
        <b-col
          v-for="item in actions"
          :key="item.id"
          md="4"
          sm="6"
        >
          <b-card
            class="text-center cursor-pointer"
            :style="{ 'background-color': `#${item.color.color}`}"
            @click="$router.push({ name: 'action-view', params: { id: item.id } })"
          >
            <div class="item-top mb-3">
              <div class="item-top-action">
                <div class="action-icon">
                  <feather-icon
                    icon="GithubIcon"
                    size="28"
                    :style="{ 'color': `#${item.color.color}`}"
                  />
                </div>
              </div>
              <div class="item-top-active text-right">
                <feather-icon
                  v-if="item.activePeriod"
                  icon="CheckCircleIcon"
                  size="28"
                  class="text-success"
                />
                <feather-icon
                  v-else
                  icon="SlashIcon"
                  size="28"
                  class="text-danger"
                />
              </div>
            </div>
            <h4>{{ item.title }}</h4>
            <b-card-text class="mt-1">
              {{ item.description }}
            </b-card-text>
            <!-- <div class="item-bottom">
              <hr>

            </div> -->
            <template #footer>
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center text-body">
                  <feather-icon
                    icon="CalendarIcon"
                    class="mr-50"
                    size="18"
                  />
                  <span class="font-weight-bold">c {{ formatDate(item.activeFrom) }} по  {{ formatDate(item.activeTo) }}</span>
                </div>
              </div>
            </template>
          </b-card>
        </b-col>
      </b-row></section>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalItems"
      :per-page="perPage"
      first-number
      last-number
      class="mb-0 mt-1 mt-sm-0"
      prev-class="prev-item"
      next-class="next-item"
    >
      <template #prev-text>
        <feather-icon
          icon="ChevronLeftIcon"
          size="18"
        />
      </template>
      <template #next-text>
        <feather-icon
          icon="ChevronRightIcon"
          size="18"
        />
      </template>
    </b-pagination>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BCardText, BFormInput, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import actionRepository from '@/repository/actionRepository'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormInput,
    BPagination,
    vSelect,
  },
  setup() {
    const toast = useToast()

    const isLoading = ref(false)
    const actions = ref([])
    const perPage = ref(12)
    const totalItems = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [1, 12, 24, 48]
    const searchQuery = ref('')

    const fetchItems = () => new Promise(resolve => {
      isLoading.value = true
      actionRepository.elementList({
        query: searchQuery.value,
        page: currentPage.value,
        limit: perPage.value,
      })
        .then(({ items, count }) => {
          actions.value = items.map(item => ({
            ...item,
          }))
          totalItems.value = count
          isLoading.value = false
          resolve()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при извлечении списка',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error ? `${error}`.replace('Error: ', '') : '',
            },
          })
        })
    })

    watch([currentPage, perPage, searchQuery], () => {
      fetchItems().then()
    })

    fetchItems().then()

    return {
      isLoading, actions, perPage, totalItems, currentPage, perPageOptions, searchQuery, formatDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.action-icon {
  display: inline-block;
  padding: 10px;
  background-color: white;
  border-radius: 15px;
}
.item-top {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-weight: 500;
  text-align: left;
  .item-top-icon {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 0.6rem;
  }
  .item-top-active {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 0.6rem;
  }
}
  .item-bottom {
    position: absolute;
    bottom: 20px;
  }
</style>
